import { useRoute } from 'vue-router';
import { ref } from 'vue';
import { apiFreeWeeklyList } from '@/request/api';

export default () => {
    const $route = useRoute();
    const makerId = $route.params.makerId;// 厂商id
    const weekList = ref([]);// 周报列表

    // 获取周报列表
    async function setWeekList() {
        const { data } = await apiFreeWeeklyList({ makerId });
        weekList.value = data || [];
        // weekList.value.unshift({ weeklyId: '', weeklyTitle: '全部' })
    }

    setWeekList();

    return { weekList }
}