<template>
    <div class="container">
        <ReportHeader>
            <div class="show_date">
                <ShowYear :year="year" />
                <ShowMonth :months="months" style="margin-left: 57px;" />
            </div>
            <div class="small_logo">
                <img src="@/assets/img/customer-week-report/cmj.png" alt="">
            </div>
        </ReportHeader>
        <BgContainer>
            <!-- <div id="move_maker"></div> -->
            <main>
                <div class="title_row" id="move_maker">
                    <PageTitle title="新闻版块" :isBack="true" :to="`/week/${makerId}?weeklyId=${$route.query.weeklyId}`" />
                    <!-- <RoundSwitch :label="`只看（11.1-11.7）`" v-model="query.justWeek" style="margin-left:30%;" /> -->
                    <!-- <WeekSelect :list="weekList" v-model="query.weeklyId"
                        :options="{ label: 'weeklyTitle', value: 'weeklyId' }" @change="handleWeekChange" /> -->
                    <el-cascader ref="cascaderRef" v-if="isCascadersShow" :props="{ checkStrictly: true }"
                        class="choose_week_cascader" popper-class="choose_week_cascader_popper" placeholder="全部"
                        :model-value="weekCascaderValue" :options="weekCascaderList" :show-all-levels="false"
                        @change="handleWeekChange" @visible-change="handleCascaderShow" />
                </div>
                <div class="first_plate">
                    <div class="li" v-for="item in modulars" :key="item"
                        :class="item.tagId === query.tagId1 ? 'active' : ''" @click="query.tagId1 = item.tagId">
                        <div class="con">
                            <h1>{{ item.tagName }}</h1>
                            <h2>{{ item.tagAlias || '-' }}</h2>
                        </div>
                    </div>
                </div>
                <div class="second_and_list">
                    <div class="second_plate">
                        <div class="all" @click="onAll">全&nbsp;&nbsp;&nbsp;&nbsp;部</div>
                        <div class="li" v-for="item in modularSeconds" :key="item.tagId">
                            <!-- <div class="item_box"
                                :class="item.tagId === query.tagId2 && !item.children?.length ? 'active' : ''"
                                @click.stop="onSecondTag(item)"> -->
                            <div class="item_box" :class="item.tagId === query.tagId2 && !query.tagId3 ? 'active' : ''"
                                @click.stop="onSecondTag(item)">
                                <ArrowDownBold class="arrow" :class="item.isExpand ? 'active' : ''"
                                    v-if="item.children?.length" />
                                <h1>{{ item.tagName }}</h1>
                                <span>({{ item.tagTotal }})</span>
                            </div>
                            <!-- <ul :style="{ height: item.isExpand ? item.children.length * 49 + 'px' : '0px' }"> -->
                            <ul :style="{ maxHeight: item.isExpand ? 5000 + 'px' : '0px' }">
                                <li v-for="f in item.children" :key="f.tagId">
                                    <div class="item_box" :class="f.tagId === query.tagId3 ? 'active' : ''"
                                        @click.stop="onThirdTag(item, f)">
                                        <h1>{{ f.tagName }}</h1>
                                        <span>({{ f.tagTotal }})</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="list">
                        <ul>
                            <li v-for="item in listData" :key="item.articleId" @click="toDetails(item)">
                                <h1>{{ item.articleTitle }}</h1>
                                <p>{{ item.description }}</p>
                                <div class="info">
                                    <span>{{ item.releaseName }}</span>
                                    <span>{{ item.showTime }}</span>
                                </div>
                                <div class="arrow">
                                    <ArrowRightBold class="icon" />
                                </div>
                            </li>
                        </ul>
                        <div class="page_box" v-if="listData.length">
                            <el-pagination small background layout="prev, pager, next" :total="total" class="pagination"
                                v-model:current-page="query.pageNum" :page-size="query.pageSize"
                                @current-change="setListData" />
                        </div>
                        <el-empty description="暂无数据" v-if="!listData.length" />
                    </div>
                </div>
            </main>
        </BgContainer>
    </div>
</template>                           

<script setup>
import { ref, computed, nextTick } from 'vue';
import BgContainer from '@/components/customer-week-report/pc/BgContainer.vue';
import ReportHeader from '@/components/customer-week-report/pc/ReportHeader.vue';
// import WeekSelect from '@/components/customer-week-report/pc/WeekSelect.vue';
import PageTitle from '@/components/customer-week-report/pc/PageTitle.vue';
import ShowYear from '@/components/customer-week-report/pc/ShowYear.vue';
import ShowMonth from '@/components/customer-week-report/pc/ShowMonth.vue';
import { ArrowRightBold, ArrowDownBold } from '@element-plus/icons-vue';
import { useRouter, useRoute } from 'vue-router';
import useParams from './useParams';
import { apiFreeWeeklyArticleList } from '@/request/api';
import cloneDeep from 'loadsh';
import { groupBy } from '@/utils';
import { getMmDd } from '@/utils/date';

const $route = useRoute();
const $router = useRouter();
const props = defineProps({ weekList: { type: Array, default: [] } })
const makerId = $route.params.makerId;// 厂商id
const total = ref(0);
const listData = ref([]);
const cascaderRef = ref(null);// 时间/周报选择器
const isCascadersShow = ref(true);// 时间/周报选择器是否显示

// 参数
const { year, months, query, modulars, modularSeconds, params, onSecondTag, onThirdTag, onAll, setModulars } = useParams(makerId, search, props);

// 按年/月/周报级联分组的周报列表
const weekCascaderList = computed(() => {
    const list = cloneDeep(props.weekList).map(item => {
        item['year'] = item.startTime?.split('-')[0];
        item['month'] = item.startTime?.split('-')[1];
        item['value'] = item.weeklyId;
        item['label'] = `${item.weeklyTitle}（${getMmDd(item?.startTime)}-${getMmDd(item?.endTime)}）`;
        return item;
    });
    // 按年份分组
    const groupObj = groupBy(list, 'year');
    for (let key in groupObj) {
        // 按月份分组
        groupObj[key] = groupBy(groupObj[key], 'month');
    }
    // 年份转为数组
    function obj2Arr(obj) {
        let result = [];
        for (let key in obj) {
            result.push({
                label: key + '月',
                value: key,
                children: obj[key]
            });
        }
        return result;
    }
    let res = [];
    for (let key in groupObj) {
        res.push({
            label: key + '年',
            value: key,
            children: obj2Arr(groupObj[key])
        });
    }
    res.unshift({ label: '全部', value: '' });
    return res;
})
// 周报选择框回显值
const weekCascaderValue = computed(() => {
    // 都没有
    if (!query.year && !query.month && !query.weeklyId) {
        return [''];
    }
    // 只有年
    if (query.year && !query.month) {
        return [query.year];
    }
    // 年+月
    if (query.year && query.month && !query.weeklyId) {
        return [query.year, query.month];
    }
    return [query.year, query.month, query.weeklyId];
})

// 周报选择框change
function handleWeekChange(e = []) {
    query.year = e[0] || null;
    query.month = e[1] || null;
    query.weeklyId = e[2] || '';
    $router.replace({ query: { ...$route.query, year: query.year, month: query.month, weeklyId: query.weeklyId } });
    setModulars();// 重新获取版块
    setListData();// 获取文章列表
    cascaderRef.value.togglePopperVisible();
}

// 周报选择框下拉框显示隐藏监听-关闭下拉框时重新渲染组件，使下一次回显时能正常展开
function handleCascaderShow(e) {
    if (e) return;
    isCascadersShow.value = false;
    nextTick(() => {
        isCascadersShow.value = true;
    })
}

// 获取文章列表
async function setListData() {
    if (!params.value.tagId1) return;
    const { data } = await apiFreeWeeklyArticleList(params.value);
    total.value = data?.total || 0;
    listData.value = data?.list || [];
}

// 搜索
function search() {
    query.pageNum = 1;
    setListData();
}

// 跳转详情
function toDetails(e) {
    const routeQuery = {
        ...$route.query,
        articleId: e.articleId,
        // weeklyId: e.weeklyId,
        pageNum: query.pageNum,
        pageSize: query.pageSize,
    }
    $router.push({
        path: `/week/${makerId}/details`,
        query: routeQuery
    })
}

query.tagId1 && setListData();

</script>

<style lang="scss" scoped>
.container {
    background-color: #E6E6E6;
    min-height: 100vh;

    .show_date {
        border-top: 1px solid var(--week-primary-color);
        position: absolute;
        top: 60px;
        right: 44px;
        padding-top: 30px;
        padding-right: 100px;
        padding-left: 10px;
        display: flex;
        align-items: center;
    }

    .small_logo {
        position: absolute;
        top: 87px;
        right: 52px;
    }

    main {
        width: 100%;
        min-height: calc(100vh - var(--week-pc-header-height));
        background-color: #f5f5f5;
        box-shadow: 0 0 20px #ccc;
        padding: 46px 80px;

        .title_row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .first_plate {
            display: flex;
            padding-top: 44px;
            justify-content: flex-start;

            .li {
                border-right: 2px solid #CFCFCF;
                text-align: center;
                cursor: pointer;
                padding: 0 40px;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    border-right: none;
                }

                .con {
                    display: inline-block;
                    text-align: left;

                    h1 {
                        font-size: 21px;
                        line-height: 1em;
                        color: #737373;
                    }

                    h2 {
                        font-size: 14px;
                        line-height: 1em;
                        color: var(--week-primary-color);
                        margin-top: 7px;
                    }

                    &::after {
                        display: block;
                        content: '';
                        width: 100%;
                        height: 5px;
                        background-color: #CBCBCB;
                        margin-top: 8px;
                    }
                }

                &.active {
                    .con {
                        h1 {
                            color: #000;
                        }

                        &::after {
                            background-color: var(--week-primary-color);
                        }
                    }
                }
            }
        }

        .second_and_list {
            display: flex;
            margin-top: 48px;

            .second_plate {
                --pr: 46px;
                width: calc(149px + var(--pr));
                padding-right: var(--pr);

                .all {
                    line-height: 34px;
                    background-color: var(--week-primary-color);
                    color: #fff;
                    padding-left: 32px;
                    font-size: 20px;
                    font-weight: bold;
                    cursor: pointer;
                }

                .li {
                    .item_box {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-top: 20px;
                        padding-bottom: 10px;
                        padding-left: 8px;
                        position: relative;
                        cursor: pointer;

                        &::after {
                            display: block;
                            content: '';
                            width: 100%;
                            height: 1px;
                            background-color: #CBCBCB;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                        }

                        h1 {
                            font-weight: bold;
                            color: #818181;
                            font-size: 16px;
                        }

                        span {
                            color: #818181;
                            font-size: 14px;
                        }

                        .arrow {
                            width: 1em;
                            height: 1em;
                            position: absolute;
                            left: -14px;
                            color: #818181;
                            transform: rotate(0deg);
                            transition: 0.5s;

                            &.active {
                                transform: rotate(180deg);
                            }
                        }

                        &.active {

                            h1,
                            span {
                                color: #000;
                            }

                            &::after {
                                height: 3px;
                                background-color: var(--week-primary-color);
                            }
                        }
                    }

                    ul {
                        transition: 0.3s;
                        overflow: hidden;

                        li {
                            .item_box {
                                padding-left: 18px;

                                h1 {
                                    font-weight: normal;
                                    font-size: 14px;
                                }

                                &.active {
                                    h1 {
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .list {
            min-height: 800px;
            background-color: #fff;
            flex: 1;
            box-shadow: 2.7px 7.5px 8.19px 0.81px rgba(0, 0, 0, 0.06);
            padding: 0 46px;
            padding-bottom: 33px;

            ul {
                padding-bottom: 50px;

                li {
                    padding-top: 35px;
                    padding-bottom: 24px;
                    border-bottom: 1px solid #CECECE;
                    position: relative;
                    padding-right: 54px;
                    cursor: pointer;

                    h1 {
                        font-size: 16px;
                        color: #000;
                        font-family: 'SourceHanSansCN-Normal-Alphabetic';
                    }

                    p {
                        color: #727272;
                        font-size: 12px;
                        margin-top: 20px;
                        line-height: 1.8em;
                    }

                    .info {
                        display: flex;
                        justify-content: space-between;
                        padding-top: 11px;

                        span {
                            font-size: 12px;
                            color: #999;
                        }
                    }

                    .arrow {
                        width: 18px;
                        height: 18px;
                        background-color: var(--week-primary-color);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: 37px;
                        right: 0;

                        .icon {
                            width: 1em;
                            height: 1em;
                            color: #fff;
                        }
                    }

                    &:hover {
                        h1 {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .page_box {
                display: flex;
                align-items: center;
                justify-content: center;

                :deep(.el-pagination .el-pager li) {
                    border-radius: 50%;
                    background-color: #CBCBCB;
                    color: #fff;
                    margin: 0 5px;
                }

                // 上下一页样式修改
                :deep(.el-pagination .btn-prev),
                :deep(.el-pagination .btn-next) {
                    background-color: #CBCBCB;
                    color: #fff;
                }

                // 上下一页不可点时，hover字体颜色修改
                :deep(.el-pagination .btn-prev:not(:disabled):hover),
                :deep(.el-pagination .btn-next:not(:disabled):hover) {
                    color: var(--week-primary-color);
                }

                :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
                    background-color: var(--week-primary-color);
                }

                :deep(.el-pagination.is-background .el-pager li:not(.active):hover) {
                    color: var(--week-primary-color);
                }

                :deep(.btn-quicknext) {
                    display: inline-flex;
                    align-items: center;
                }
            }
        }
    }
}
</style>

<style lang="scss">
.choose_week_cascader {
    width: 220px;

    .el-input__inner {
        border-color: #B7B7B7 !important;
        border-radius: 0;
        background: none;

        &:focus {
            border-color: #B7B7B7 !important;
        }
    }

    .el-input__suffix {
        background-color: var(--week-primary-color);
        right: 0;
        width: 32px;

        .el-input__suffix-inner {
            width: 100%;

            .el-input__icon {
                width: 100%;
                color: #fff;
            }
        }
    }
}

.choose_week_cascader_popper {
    .el-cascader-menu {
        min-width: 130px;
    }

    .el-radio__inner:hover {
        border-color: var(--week-primary-color);
    }

    .el-radio__input.is-checked .el-radio__inner {
        border-color: var(--week-primary-color);
        background: var(--week-primary-color);
    }

    // 打开节点
    .el-cascader-node.in-active-path {
        background-color: #f5f7fa;
        color: #606266 !important;
    }

    // 选中路径
    .el-cascader-node.in-checked-path {
        color: var(--week-primary-color) !important;
    }

    // 选中节点
    .el-cascader-node.is-active {
        color: var(--week-primary-color);
    }

    // .el-cascader-node.in-checked-path.in-active-path {
    //     background-color: #fff;
    // }
}
</style>