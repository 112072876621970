<template>
    <div class="container">
        <ReportHeader>
            <div class="choose_date">
                <ShowYear :year="year" />
            </div>
            <div class="small_logo">
                <img src="@/assets/img/customer-week-report/cmj.png" alt="">
            </div>
            <div class="choose_month">
                <ShowMonth :months="months" />
            </div>
        </ReportHeader>
        <div class="title_row">
            <PageTitle title="新闻版块" :isBack="true" :to="`/week/${makerId}?weeklyId=${$route.query.weeklyId}`" />
            <WeekSelect :list="weekListCom" v-model="query.weeklyId" :options="{ label: 'weeklyTitle', value: 'weeklyId' }"
                @change="handleWeekChange" />
        </div>
        <div class="first_plate">
            <div class="li" v-for="item in modulars" :key="item" :class="item.tagId === query.tagId1 ? 'active' : ''"
                @click="query.tagId1 = item.tagId">
                <div class="con">
                    <h1>{{ item.tagName }}</h1>
                    <h2>{{ item.tagAlias || '-' }}</h2>
                </div>
            </div>
        </div>
        <div class="second_plate scrollbar" v-if="modularSeconds.length > 1">
            <ul>
                <li class="tag2 all" @click="query.tagId2 = ''">
                    <span>全&nbsp;&nbsp;部</span>
                </li>
                <li class="tag2" v-for="item in modularSeconds" :key="item"
                    :class="item.tagId === query.tagId2 ? 'active' : ''" @click="query.tagId2 = item.tagId">
                    <p>{{ item.tagName }}&nbsp;</p>
                    <span>({{ item.tagTotal }})</span>
                </li>
            </ul>
        </div>
        <div class="content" ref="slideDom">
            <ul>
                <li v-for="item in listData" :key="item" @click="toDetails(item)" :class="item.focus ? 'focus' : ''">
                    <!-- <p class="tit" v-if="isMonth(listData[index - 1], item)">{{ getMonth(item.showTime) }}月</p> -->
                    <div class="con">
                        <h1>{{ item.articleTitle }}</h1>
                        <p>{{ item.description }}</p>
                        <div class="info">
                            <span>{{ item.releaseName }}</span>
                            <span>{{ item.showTime }}</span>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="no_more">
                <p v-if="loading || listData.length < total">加载中...</p>
                <p v-if="!loading && listData.length >= total">没有更多了</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, nextTick, computed, watch } from 'vue';
import ReportHeader from '@/components/customer-week-report/phone/ReportHeader.vue';
import ShowYear from '@/components/customer-week-report/phone/ShowYear.vue';
import ShowMonth from '@/components/customer-week-report/phone/ShowMonth.vue';
import WeekSelect from '@/components/customer-week-report/phone/WeekSelect.vue';
import PageTitle from '@/components/customer-week-report/phone/PageTitle.vue';
import { useRouter, useRoute } from 'vue-router';
import { apiFreeWeeklyArticleList } from '@/request/api';
import { debounce } from 'loadsh';
import useSlide from './useSlide';
import useParams from './useParams';
import useWxShare from '@/utils/useWxShare';
import { getMmDd } from '@/utils/date';
import { cloneDeep } from 'loadsh';

const $router = useRouter();
const $route = useRoute();
const props = defineProps({ weekList: { type: Array, default: [] } })
const weekListCom = computed(() => {
    const res = cloneDeep(props.weekList);
    res.unshift({ weeklyId: '', weeklyTitle: '全部' });
    return res;
})
const makerId = $route.params.makerId;// 厂商id
const loading = ref(false);
const total = ref(0);
const listData = ref([]);
// 周报名称
const weekName = computed(() => {
    if (!query.weeklyId) return '';
    const obj = props.weekList.find(item => item.weeklyId === query.weeklyId);
    const res = `${obj?.weeklyTitle}（${getMmDd(obj?.startTime)}-${getMmDd(obj?.endTime)}）`;
    return res;
})

// 参数
const { year, months, query, modulars, modularSeconds, params } = useParams(makerId, search, () => { setWxShare() }, props);

// 周报id切换
function handleWeekChange(e) {
    $router.replace({
        query: { ...$route.query, weeklyId: e }
    })
}

// 获取文章列表
async function setListData() {
    loading.value = true;
    try {
        const { data } = await apiFreeWeeklyArticleList({ ...params.value, pageNum: params.value.pageNum + 1 });
        total.value = data?.total || 0;
        listData.value = [...listData.value, ...(data?.list || [])];
        query.pageNum += 1;
    } finally {
        nextTick(() => {
            loading.value = false;
        })
    }
}

// 搜索
function search() {
    query.pageNum = 0;
    listData.value = [];
    total.value = 0;
    loading.value = false;
    setListData();
}

// 点击文章
function toDetails(e) {
    e.focus = true;
    const routeQuery = {
        ...$route.query,
        articleId: e.articleId,
        // weeklyId: e.weeklyId,
        pageNum: query.pageNum,
        pageSize: query.pageSize,
    }
    $router.push({
        path: `/week/${makerId}/details`,
        query: routeQuery
    })
}

// 滚动
const handleScroll = debounce(() => {
    // 滚动条在Y轴滚动过的高度
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    let scrollHeight = document.documentElement.scrollHeight//滚动条的高度
    let clientHeight = document.documentElement.clientHeight//浏览器的可视高度
    if (scrollTop + clientHeight + 100 >= scrollHeight) {
        if (listData.value.length >= total.value && loading.value) return;
        setListData();
    }
}, 100)

// 滑动
const { slideDom, showTag2 } = useSlide({ tag2Change: (e) => { query.tagId2 = e }, modularSeconds });

// 微信分享
const { setShareContent } = useWxShare();

// 微信分享方法-自动更新描述
function setWxShare() {
    nextTick(() => {
        let desc = '';
        if (weekName.value) {
            desc = weekName.value + '-';
        }
        const tagObj = modulars.value.find(item => item.tagId === query.tagId1);
        if (tagObj) {
            desc += tagObj.tagName
        }
        const link = location.origin + `/#/week/${$route.params.makerId}?weeklyId=${$route.query.weeklyId}`;
        setShareContent({ title: '餐饮资讯周报', link, desc });
    })
}

watch(() => props.weekList, (newVal) => {
    newVal?.length && setWxShare();
})

watch(() => modulars.value, (newVal) => {
    newVal?.length && setWxShare();
})

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
    setTimeout(() => {
        showTag2(query.tagId2);
    }, 300)
})

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
})

setListData();
</script>

<style lang="scss" scoped>
.container {
    min-height: 100vh;
    background-color: #f5f5f5;

    .choose_date {
        width: 215px;
        border-top: 1px solid var(--week-primary-color);
        position: absolute;
        top: 60px;
        right: 14px;
        padding-top: 10px;
        padding-left: 80px;
        display: flex;
        align-items: center;
    }

    .small_logo {
        width: 30px;
        position: absolute;
        top: 66px;
        right: 17px;

        img {
            max-width: 100%;
        }
    }

    .choose_month {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 3px;
    }

    .title_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 22px 17px 26px 17px;
    }

    .first_plate {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .li {
            flex: 1;
            border-right: 1px solid #CFCFCF;
            text-align: center;
            cursor: pointer;
            padding: 0 12px;

            &:last-child {
                border-right: none;
            }

            .con {
                display: inline-block;
                text-align: left;
                position: relative;
                height: 100%;
                padding-bottom: 22px;

                h1 {
                    font-size: 13px;
                    line-height: 1em;
                    color: #737373;
                    text-align: center;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 2px;
                }

                h2 {
                    width: 100%;
                    font-size: 6px;
                    line-height: 1.4em;
                    color: var(--week-primary-color);
                    margin-top: 4px;
                    position: absolute;
                    bottom: 6px;
                    text-align: center;
                }

                &::after {
                    display: block;
                    content: '';
                    width: 100%;
                    height: 4px;
                    background-color: #CBCBCB;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }

            &.active {
                .con {
                    h1 {
                        color: #000;
                    }

                    &::after {
                        background-color: var(--week-primary-color);
                    }
                }
            }
        }
    }

    .second_plate {
        overflow-x: auto;
        margin-top: 6px;
        width: 100%;
        text-align: center;
        height: 36px;
        background-color: #fff;
        box-shadow: 2.7px 7.5px 8.19px 0.81px rgba(0, 0, 0, 0.06);
        margin-bottom: 10px;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        ul {
            display: inline-flex;
            height: 100%;
            padding: 8px 0;
            padding-left: 0;
            align-items: center;

            li {
                height: 18px;
                margin: 0 8px;
                cursor: pointer;
                white-space: nowrap;
                position: relative;
                display: flex;
                align-items: center;

                &.all {
                    padding-left: 8px;

                    span {
                        color: #fff;
                        background-color: var(--week-primary-color);
                        font-size: 12px;
                        padding: 0 4px;
                    }

                    &::after {
                        display: none;
                    }
                }

                p,
                span {
                    display: block;
                    position: relative;
                    font-size: 12px;
                    z-index: 1;
                    color: #292929;
                }

                &::after {
                    display: block;
                    content: '';
                    width: 100%;
                    height: 2px;
                    background-color: var(--week-primary-color);
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    border-radius: 2px;
                    z-index: 0;
                    opacity: 0;
                    transition: .5s;
                }

                &.active {

                    p {
                        font-size: 12px;
                        font-weight: 700;
                        color: #1a1a1a;
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .content {
        min-height: 50vh;
        /* 兼容 iOS < 11.2 */
        padding-bottom: constant(safe-area-inset-bottom);
        /* 兼容 iOS >= 11.2 */
        padding-bottom: env(safe-area-inset-bottom);

        ul {
            // min-height: 50px;
            // border: 2px solid red;
            // transform: translateX(100px);
            padding: 0 17px;
            background-color: #fff;

            li {
                border-bottom: 1px solid #CBCBCB;

                &:last-child {
                    border-bottom: none;
                }

                &:active {
                    background-color: #f2f2f2;
                }

                &.focus {
                    .con {

                        h1,
                        p {
                            opacity: .4;
                        }
                    }
                }

                p.tit {
                    background-color: #F9F9F9;
                    line-height: 36px;
                    color: #ACACAC;
                }

                .con {
                    padding-bottom: 22px;

                    h1 {
                        font-weight: 500;
                        color: #070A16;
                        font-size: 16px;
                        margin-bottom: 8px;
                        line-height: 24px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        padding-top: 30px;
                        padding-bottom: 14px;
                    }

                    p {
                        color: #94969B;
                        font-size: 12px;
                        line-height: 20px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }

                    .info {
                        display: flex;
                        justify-content: space-between;
                        padding-top: 12px;

                        span {
                            font-size: 12px;
                            color: #999;
                        }
                    }
                }
            }
        }

        .no_more {
            text-align: center;
            font-size: 12px;
            color: #646464;
            padding: 20px 0;
        }
    }
}
</style>