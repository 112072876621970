<template>
    <PcContainer v-if="device === 'PC'" :weekList="weekList" />
    <PhoneContainer v-if="device === 'PHONE'" :weekList="weekList" />
</template>

<script>
export default {
    name: "WeekPlate"
}
</script>

<script setup>
import useIsDevice from '@/utils/customer-week-report/useIsDevice';
import PcContainer from './pc/index.vue';
import PhoneContainer from './phone/index.vue';
import useDict from './useDict';
import { onBeforeRouteLeave } from 'vue-router';
import useKeepAlive from '@/store/useKeepAlive';

const keepAliveStore = useKeepAlive();// 缓存

// 设备判断 PC | PHONE
const { device } = useIsDevice();

// 字典
const { weekList } = useDict();

// 离开页面时如果时跳转详情页，缓存当前页面，如果不是跳转详情页，不缓存当前页面
onBeforeRouteLeave((to, _from, next) => {
    if (to.name === 'WeekDetails') {
        keepAliveStore.setKeepRoutes(['WeekPlate']);
    } else {
        keepAliveStore.setKeepRoutes([]);
    }
    next();
})
</script>