import { ref, computed, reactive, watch } from 'vue';
import { apiFreeWeeklyYears, apiFreeWeeklyModularStats } from '@/request/api';
import { useRoute, useRouter } from 'vue-router';
import { cloneDeep } from 'loadsh';

export default (makerId, search, setWxShare, props) => {

    const $route = useRoute();
    const $router = useRouter();
    // 所有版块
    const modulars = ref([]);
    // 二级版块列表
    const modularSeconds = computed(() => {
        const index = modulars.value.findIndex(item => item.tagId === query.tagId1);
        if (index === -1) return [];
        return modulars.value[index].children || [];
    })
    // 1级版块文章总数
    const tag1Total = computed(() => {
        const index = modulars.value.findIndex(item => item.tagId === query.tagId1);
        if (index === -1) return 0;
        return modulars.value[index].tagTotal || 0;
    })
    // 查询参数
    const query = reactive({
        weeklyId: $route.query.weeklyId || '',
        tagId1: $route.query.tagId1 || '',
        tagId2: $route.query.tagId2 || '',
        pageNum: 0,
        pageSize: 20,
    });
    const params = computed(() => {
        const res = cloneDeep(query);
        res['makerId'] = makerId;
        return res;
    })
    const years = ref([]);// 年份列表
    // 头部显示年份，如果周报存在，则为当前周报年份，如果周报不存在，则为当前实际年份
    const year = computed(() => {
        const index = props.weekList.findIndex(item => item.weeklyId === query.weeklyId);
        if (index === -1) {
            return new Date().getFullYear();
        } else {
            return props.weekList[index].startTime?.split('-')[0];
        }
    })
    // 头部高亮月份列表
    const months = computed(() => {
        if (query.weeklyId) {
            const week = props.weekList.find(item => item.weeklyId === query.weeklyId);
            if (!week) return [];
            return [week.startTime?.split('-')[1]];
        } else {
            const index = years.value.findIndex(item => item.year === String(new Date().getFullYear()));
            if (index === -1) return [];
            return years.value[index].monthData.filter(item => item.total).map(item => item.month.split('-')[1]);
        }
    })

    // 获取年月
    async function setYears() {
        const { data } = await apiFreeWeeklyYears({ makerId });
        years.value = data || [];
    }

    // 获取版块
    async function setModulars() {
        const params = { makerId };
        if (query.weeklyId) {
            params['weeklyId'] = query.weeklyId;
        }
        const { data } = await apiFreeWeeklyModularStats(params);
        modulars.value = data || [];
        if (!query.tagId1 && modulars.value.length) {
            query.tagId1 = modulars.value[0].tagId;
        }
    }

    // 修改$route.query
    function setQuery(key, value) {
        const routeQuery = cloneDeep($route.query);
        $router.replace({ query: { ...routeQuery, [key]: value } });
    }

    // 监听周报改变
    watch(() => query.weeklyId, (newVal) => {
        setQuery('weeklyId', newVal);
        setModulars();// 重新获取版块
        search();
        setWxShare();// 重新设置分享信息
    })

    // 监听一级版块改变，改变时清空二级版块选中
    watch(() => query.tagId1, (newVal) => {
        setQuery('tagId1', newVal);
        query.tagId2 = '';
        search();
        setWxShare();// 重新设置分享信息
    })

    // 监听2级版块
    watch(() => query.tagId2, (newVal) => {
        setTimeout(() => {
            setQuery('tagId2', newVal);
        }, 0)
        search();
    })

    setYears();
    setModulars();

    return { year, months, query, modulars, modularSeconds, params, tag1Total }
}