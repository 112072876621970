import { ref, onMounted, onBeforeUnmount, computed, nextTick, watch } from 'vue';
import { useRoute } from 'vue-router';

// 判断是否在微信中打开
function isWx() {
    // 获取 User Agent
    const userAgent = navigator.userAgent.toLowerCase();
    // 判断是否在微信中打开
    if (userAgent.indexOf('micromessenger') === -1) {
        return false;
    } else {
        return true;
    }
}

//获得角度
function getAngle(angx, angy) {
    return Math.atan2(angy, angx) * 180 / Math.PI;
};

//根据起点终点判断 false为上下，true为左右
function getDirection(startx, starty, endx, endy) {
    const angx = endx - startx;
    const angy = endy - starty;
    const angle = getAngle(angx, angy);
    if ((angle < -30 && angle > -150) || (angle > 30 && angle < 150)) {
        return false;
    } else {
        return true;
    }
}

export default ({ tag2Change, modularSeconds }) => {
    const $route = useRoute();
    const slideDom = ref(null);
    const startX = ref(0);// 开始位置x
    const startY = ref(0);// 开始位置y
    const tagId2 = computed(() => $route.query.tagId2);
    const currentIndex = ref(-1);//当前二级板块下标
    const MIN = 50;// 最少滑动距离

    // 处理开始
    function handleStart(e) {
        startX.value = e.changedTouches[0].clientX;
        startY.value = e.changedTouches[0].clientY;
        currentIndex.value = modularSeconds.value.findIndex(item => item.tagId === tagId2.value);
    }

    // 处理滑动中
    function handleMove(e) {
        const dir = getDirection(startX.value, startY.value, e.changedTouches[0].clientX, e.changedTouches[0].clientY);
        if (!dir) return;
        slideDom.value.style.transform = `translateX(${e.changedTouches[0].clientX - startX.value}px)`
    }

    // 处理滑动结束
    function handleEnd(e) {
        slideDom.value.style.transform = `translateX(0px)`;
        const diff = startX.value - e.changedTouches[0].clientX;
        if (Math.abs(diff) < MIN || !modularSeconds.value?.length) {// 滑动距离过短或者二级板块不存在
            slideDom.value.style.transform = `translateX(0px)`;
            return;
        }
        if (diff > 0 && currentIndex.value + 1 < modularSeconds.value.length) {// 左滑-下一个
            currentIndex.value += 1;
        } else if (diff < 0 && currentIndex.value > -1) {// 右滑-上一个
            currentIndex.value -= 1;
        }
        tag2Change(modularSeconds.value[currentIndex.value]?.tagId || '');
    }

    // 板块滑动到显示
    function showTag2(tagId2) {
        let index = 0;
        if (tagId2) {
            index = modularSeconds.value.findIndex(item => item.tagId === tagId2);
            index++;
        } else {
            index = 0;
        }
        if (index === -1) return;
        const dom = document.querySelectorAll('.tag2')[index];
        if (!dom) return;
        nextTick(() => {
            setTimeout(() => {
                dom.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                slideDom.value.style.transform = `translateX(0px)`;
            }, 200)
        })
    }

    watch(() => tagId2.value, () => {
        showTag2(tagId2.value)
    })

    onMounted(() => {
        if (!isWx()) return;
        slideDom.value.addEventListener('touchstart', handleStart);// 开始滑动
        slideDom.value.addEventListener('touchmove', handleMove);// 滑动中
        slideDom.value.addEventListener('touchend', handleEnd);// 滑动结束
    })

    onBeforeUnmount(() => {
        if (!isWx()) return;
        slideDom.value.removeEventListener('touchstart', handleStart);// 开始滑动
        slideDom.value.removeEventListener('touchmove', handleMove);// 滑动中
        slideDom.value.removeEventListener('touchend', handleEnd);// 滑动结束
    })

    return { slideDom, showTag2 }
}