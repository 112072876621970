import { ref, computed, reactive, watch } from 'vue';
import { apiFreeWeeklyYears, apiFreeWeeklyModularStats } from '@/request/api';
import { useRoute, useRouter } from 'vue-router';
import { cloneDeep } from 'loadsh';

function moveToEl() {
    const scroll = 206 + 20;
    const t = document.documentElement.scrollTop || document.body.scrollTop;
    if (t < scroll) return;
    window.scrollTo({ top: scroll });
    // const dom = document.querySelector(el);
    // if (!dom) return;
    // setTimeout(() => {
    //     dom.scrollIntoView({
    //         behavior: 'instant',
    //         block: 'start',
    //     });
    // }, 0)
}

export default (makerId, search, props) => {

    const $route = useRoute();
    const $router = useRouter();
    // 所有版块
    const modulars = ref([]);
    // 二级版块列表
    const modularSeconds = ref([]);
    // 1级版块文章总数
    const tag1Total = computed(() => {
        const index = modulars.value.findIndex(item => item.tagId === query.tagId1);
        if (index === -1) return 0;
        return modulars.value[index].tagTotal || 0;
    })
    // 查询参数
    const query = reactive({
        weeklyId: $route.query.weeklyId || '',
        year: $route.query.year || null,
        month: $route.query.month || null,
        tagId1: $route.query.tagId1 || '',
        tagId2: $route.query.tagId2 || '',
        tagId3: $route.query.tagId3 || '',
        pageNum: 1,
        pageSize: 10,
    });
    const params = computed(() => {
        const res = cloneDeep(query);
        res['makerId'] = makerId;
        return res;
    })
    const years = ref([]);// 年份列表
    // 头部显示年份，如果周报存在，则为当前周报年份，如果周报不存在，则为当前实际年份
    const year = computed(() => {
        if (query.year) {
            return query.year;
        } else {
            return new Date().getFullYear();
        }
    })
    // 头部高亮月份列表
    const months = computed(() => {
        if (query.month) {
            return [query.month];
        } else {
            const y = year.value || new Date().getFullYear();
            const index = years.value.findIndex(item => item.year == y);
            if (index === -1) return [];
            return years.value[index].monthData.filter(item => item.total).map(item => item.month.split('-')[1]);
        }
    })

    // 获取年月
    async function setYears() {
        const { data } = await apiFreeWeeklyYears({ makerId });
        years.value = data || [];
    }

    // 获取版块
    async function setModulars() {
        const params = { makerId };
        params['weeklyId'] = query.weeklyId || '';
        params['year'] = query.year || null;
        params['month'] = query.month || null;
        const { data } = await apiFreeWeeklyModularStats(params);
        modulars.value = data || [];
        if (!query.tagId1 && modulars.value.length) {
            query.tagId1 = modulars.value[0].tagId;
        }
        setModularSeconds();
    }

    // 设置二级版块
    function setModularSeconds() {
        const list = cloneDeep(modulars.value);
        const index = list.findIndex(item => item.tagId === query.tagId1);
        if (index === -1) return [];
        const res = modulars.value[index].children || [];
        modularSeconds.value = res.map(item => {
            item['isExpand'] = false;
            const i = item.children?.findIndex(item => item.tagId === query.tagId3);
            if (i !== -1) {
                item['isExpand'] = true;
            }
            return item;
        })
    }

    // 点击二级版块
    function onSecondTag(tag) {
        tag.isExpand = !tag.isExpand;
        modularSeconds.value.forEach(item => {
            if (item.tagId !== tag.tagId) {
                item.isExpand = false;
            }
        })
        // if (tag.children?.length) return;// 如果存在三级，不更改版块参数
        // 不存在三级
        query.tagId2 = tag.tagId;
        query.tagId3 = '';
        $router.replace({ query: { ...$route.query, tagId2: tag.tagId, tagId3: '' } });
        search();
        moveToEl();

    }

    // 点击三级版块
    function onThirdTag(tag2, tag3) {
        query.tagId2 = tag2.tagId;
        query.tagId3 = tag3.tagId;
        $router.replace({ query: { ...$route.query, tagId2: tag2.tagId, tagId3: tag3.tagId } });
        search();
        moveToEl();
    }

    // 点击全部
    function onAll() {
        query.tagId2 = '';
        query.tagId3 = '';
        $router.replace({ query: { ...$route.query, tagId2: '', tagId3: '' } });
        search();
    }

    // 修改$route.query
    function setQuery(key, value) {
        const routeQuery = cloneDeep($route.query);
        $router.replace({ query: { ...routeQuery, [key]: value } });
    }

    // 监听一级版块改变，改变时清空二级版块选中
    watch(() => query.tagId1, (newVal) => {
        setQuery('tagId1', newVal);
        query.tagId2 = '';
        query.tagId3 = '';
        setModularSeconds();
        search();
    })

    setYears();
    setModulars();
    watch(() => props.weekList, (newVal) => {
        if (!newVal) return;
        if (query.weeklyId) {
            const obj = props.weekList.find(item => item.weeklyId === query.weeklyId);
            query.year = obj?.startTime?.split('-')[0] || null;
            query.month = obj?.startTime?.split('-')[1] || null;
        }
    })

    return { year, months, query, modulars, modularSeconds, params, tag1Total, onSecondTag, onThirdTag, onAll, setModulars }
}